var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('header-view',{attrs:{"title":"Work Crews","previous_page":"NO_BACK_BUTTON","btnOptions":[
      {
        name: 'New',
        icon: 'mdi-plus',
        action: function () {
          _vm.$router.push({name: 'CrewCreate'});
        },
      } ]}}),_c('main-container',{attrs:{"id":"crewsList"}},[(_vm.crews && _vm.crews.length > 0 && _vm.showTable)?_c('div',{staticClass:"mt-2"},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.crews,"id":"crewsTable"},scopedSlots:_vm._u([{key:"item",fn:function(props){return [_c('tr',[_c('td',[_vm._v(_vm._s(props.item.uuid))]),_c('td',[_vm._v(_vm._s(props.item.name))]),_c('td',[_vm._v(_vm._s(props.item.foreman_name))]),_c('td',[_c('router-link',{attrs:{"to":'/crews/view/' + props.item.uuid}},[_vm._v(" View ")])],1)])]}}],null,false,1924270270)})],1):_vm._e()])],1)}
var staticRenderFns = []

export { render, staticRenderFns }