<!-- ----------------------------------------------------------------------- -->
<!--
name    : APP CREW LIST

type    : view

uses    : header-view
          main-container

route   : /crews (default route)
 -->
<!-- ----------------------------------------------------------------------- -->
<template>
  <div>
    <header-view
      title="Work Crews"
      previous_page="NO_BACK_BUTTON"
      :btnOptions="[
        {
          name: 'New',
          icon: 'mdi-plus',
          action: () => {
            $router.push({name: 'CrewCreate'});
          },
        },
      ]" />
    <main-container id="crewsList">
      <div v-if="crews && crews.length > 0 && showTable" class="mt-2">
        <v-data-table
          :headers="headers"
          :items="crews"
          class="elevation-1"
          id="crewsTable">
          <template v-slot:item="props">
            <tr>
              <td>{{ props.item.uuid }}</td>
              <td>{{ props.item.name }}</td>
              <td>{{ props.item.foreman_name }}</td>
              <td>
                <router-link :to="'/crews/view/' + props.item.uuid">
                  View
                </router-link>
              </td>
            </tr>
          </template>
        </v-data-table>
      </div>
    </main-container>
  </div>
</template>

<script>
  import axios from 'axios';
  import HeaderView from '@/components/header-view';
  import MainContainer from '@/components/main-container';

  export default {
    name: 'CrewList',
    components: {
      'header-view': HeaderView,
      'main-container': MainContainer,
    },
    data() {
      return {
        headers: [
          {
            text: 'ID',
            align: 'left',
            sortable: false,
            value: 'uuid',
          },
          {
            text: 'Name',
            value: 'name',
          },
          {
            text: 'Foreman Name',
            value: 'foreman_name',
          },
          {
            text: 'View',
            value: 'links',
          },
        ],
        showTable: false,
        crews: [],
      };
    },
    async mounted() {
      // console.log( "heres the url" )
      // console.log( window.location.protocol + '//' + window.location.host + '/service_tenants/crews/all' )

      // Get the access token from the auth wrapper
      const accessToken = await this.$auth.getTokenSilently();
      await axios({
        method: 'GET',
        url: '/service_tenants/crews/all',
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      })
        .then((results) => {
          this.crews = results.data;

          axios({
            method: 'GET',
            url: '/service_users/users/all',
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          })
            .then((userResults) => {
              // console.log( "user result" )
              // console.log( userResults )

              // console.log( this.crews )
              for (var i = 0; i < this.crews.length; i++) {
                for (var j = 0; j < userResults.data.length; j++) {
                  // console.log( "comparing " + this.crews[ i ].foreman_uuid + " and " + userResults.data[ j ].uuid )
                  if (userResults.data[j].uuid == this.crews[i].foreman_uuid) {
                    this.crews[i].foreman_name = userResults.data[j].name;
                    // console.log( "this one is updated" )
                    // console.log( this.crews[ i ] )
                  }
                }
              }
              this.showTable = true;
            })
            .catch((err) => {
              console.log(err);
            });
        })
        .catch((err) => {
          console.log(err);
        });
    },
  };
</script>

<!-- Add 'scoped' attribute to limit CSS to this component only -->
<style scoped lang="scss">
  h3 {
    margin: 40px 0 0;
  }
  ul {
    list-style-type: none;
    padding: 0;
  }
  li {
    display: inline-block;
    margin: 0 10px;
  }
  a {
    color: #42b983;
  }
  .clientList {
    text-align: left;
    width: 40%;
    margin: 0 auto;
  }
</style>
